.user-container{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    .user-content-container{
        width: 100%;
        min-height: calc(100% - 8rem);
        display: flex;
        flex-direction: column;
        .user-content-header-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .user-content-header-left{
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .user-content-title{
                    font-size: 1.6rem;
                    min-width: 120px;
                }
                .user-content-search-input-1{
                    min-height: 2rem;
                    width: 8rem;
                    border: 1px solid #a6a6a6;
                    font-size: 1rem;
                    outline: none;
                    margin-left: 3rem;
                    background: black;
                    color: white;
                    padding: 0 0.5rem;
                }
                .user-content-search-input-2{
                    min-height: 2rem;
                    width: 8rem;
                    border: 1px solid #a6a6a6;
                    font-size: 1rem;
                    outline: none;
                    margin-left: 0.5rem;
                    background: black;
                    color: white;
                    padding: 0 0.5rem;
                }
                .user-content-dropdown-first{
                    color: #a6a6a6;
                    cursor: pointer;
                    outline: none;
                    font-size: 1rem;
                    border: 1px solid #a6a6a6;
                    min-height: 2rem;
                    min-width: 5.5rem;
                    margin-left: 2rem;
                    .react-select__control{
                        background: black;
                        outline: none;
                        border: none;
                        min-height: 1.5rem;
                        box-shadow: none;
                        .react-select__value-container{
                            border: none;
                            .react-select__single-value{
                                font-size: 1rem;
                                color: white;
                            }
                        }
                        .react-select__indicators{
                            border: none;
                            padding: 0;
                            .react-select__indicator-separator{
                                display: none;
                            }
                            .react-select__indicator{
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
                .user-content-search-btn{
                    background: #404040;
                    color: #a8a8a8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: calc(2rem - 1rem);
                    padding: 0.5rem 1.5rem;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    min-width: 40px;
                    text-decoration: none;
                    &:hover{
                        color: #777676;
                    }
                }
            }
            .user-content-header-right{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                .user-register{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                    &:hover{
                        color: #777676;
                    }
                }
            }
        }
        .user-content-body-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .user-content-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .user-content-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 3vw;
                        width: 10vw;
                    }
                    &:nth-child(2){
                        width: 10vw;
                    }
                    &:nth-child(3){
                        width: 15vw;
                    }
                    &:nth-child(4){
                        width: 10vw;
                    }
                    &:nth-child(5){
                        width: 10vw;
                    }
                    &:nth-child(6){
                        width: 10vw;
                    }
                    &:nth-child(7){
                        width: 10vw;
                    }
                    &:nth-child(8){
                        width: 10vw;
                    }
                }
            }
            .user-content-body{
                display: flex;
                width: 100%;
                min-height: calc(100vh - 18rem);
                flex-direction: column;
            }
        }
    }
}
.vid-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    .vid-sticky{
        width: 100vw;
        min-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        .vid-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .vid-header-left{
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .vid-header-title{
                    font-size: 1.6rem;
                    min-width: 130px;
                }
                .test-content-dropdown-third{
                    color: #a6a6a6;
                    cursor: pointer;
                    outline: none;
                    font-size: 1rem;
                    border: 1px solid #a6a6a6;
                    min-height: 2rem;
                    min-width: 5.5rem;
                    margin-left: 2rem;
                    .react-select__control{
                        background: black;
                        outline: none;
                        border: none;
                        min-height: 1.5rem;
                        box-shadow: none;
                        .react-select__value-container{
                            border: none;
                            .react-select__single-value{
                                font-size: 1rem;
                                color: white;
                            }
                        }
                        .react-select__indicators{
                            border: none;
                            padding: 0;
                            .react-select__indicator-separator{
                                display: none;
                            }
                            .react-select__indicator{
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
                .test-content-dropdown-second{
                    color: #a6a6a6;
                    cursor: pointer;
                    outline: none;
                    font-size: 1rem;
                    border: 1px solid #a6a6a6;
                    min-height: 2rem;
                    min-width: 8rem;
                    margin-left: 0.5rem;
                    .react-select__control{
                        background: black;
                        outline: none;
                        border: none;
                        min-height: 1.5rem;
                        box-shadow: none;
                        .react-select__value-container{
                            border: none;
                            .react-select__single-value{
                                font-size: 1rem;
                                color: white;
                            }
                        }
                        .react-select__indicators{
                            border: none;
                            padding: 0;
                            .react-select__indicator-separator{
                                display: none;
                            }
                            .react-select__indicator{
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
                .test-content-dropdown-first{
                    color: #a6a6a6;
                    cursor: pointer;
                    outline: none;
                    font-size: 1rem;
                    border: 1px solid #a6a6a6;
                    min-height: 2rem;
                    min-width: 5.5rem;
                    margin-left: 0.5rem;
                    .react-select__control{
                        background: black;
                        outline: none;
                        border: none;
                        min-height: 1.5rem;
                        box-shadow: none;
                        .react-select__value-container{
                            border: none;
                            .react-select__single-value{
                                font-size: 1rem;
                                color: white;
                            }
                        }
                        .react-select__indicators{
                            border: none;
                            padding: 0;
                            .react-select__indicator-separator{
                                display: none;
                            }
                            .react-select__indicator{
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
                .vid-header-search-btn{
                    background: #404040;
                    color: #a8a8a8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: calc(2rem - 1rem);
                    padding: 0.5rem 1.5rem;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    min-width: 40px;
                    text-decoration: none;
                    &:hover{
                        color: #777676;
                    }
                }
            }
            .vid-header-right{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                .vid-register{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                    &:hover{
                        color: #777676;
                    }
                }
            }
        }
        .vid-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .vid-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .vid-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 5vw;
                        width: 14vw;
                    }
                    &:nth-child(2){
                        width: 12vw;
                    }
                    &:nth-child(3){
                        width: 12vw;
                    }
                    &:nth-child(4){
                        width: 12vw;
                    }
                    &:nth-child(5){
                        width: 10vw;
                    }
                }
                .mat-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 10vw;
                        width: 15vw;
                    }
                    &:nth-child(2){
                        width: 15vw;
                    }
                    &:nth-child(3){
                        width: 15vw;
                    }
                    &:nth-child(4){
                        width: 15vw;
                    }
                }
            }
            .vid-content-body{
                width: 100%;
            }
        }
    }
}
.group-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    .group-sticky{
        width: 100vw;
        min-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        .group-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .group-header-left{
                width: 80%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .group-header-title{
                    font-size: 1.6rem;
                    min-width: 130px;
                }
                .group-header-search{
                    min-height: 2rem;
                    width: 8rem;
                    border: 1px solid #a6a6a6;
                    font-size: 1rem;
                    outline: none;
                    margin-left: 3rem;
                    background: black;
                    color: white;
                    padding: 0 0.5rem;
                }
                .group-header-btn{
                    background: #404040;
                    color: #a8a8a8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: calc(2rem - 1rem);
                    padding: 0.5rem 1.5rem;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    min-width: 40px;
                    &:hover{
                        color: #777676;
                    }
                }
            }
            .group-header-right{
                width: 20%;
                display: flex;
                justify-content: flex-end;
                .group-new{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                    &:hover{
                        color: #777676;
                    }
                }
            }
        }
        .group-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .group-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .group-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: flex-start;
                    width: 20vw;
                    margin: 0 15vw;
                    height: 100%;
                    align-items: center;
                }
            }
            .group-body-content{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
}

.file-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;
    .file-sticky{
        width: 100vw;
        min-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        .file-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .file-header-left{
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .file-header-title{
                    font-size: 1.6rem;
                    min-width: 130px;
                }
                .file-header-dropdown{
                    color: #a6a6a6;
                    cursor: pointer;
                    outline: none;
                    font-size: 1rem;
                    border: 1px solid #a6a6a6;
                    min-height: 2rem;
                    min-width: 5.5rem;
                    &:nth-child(2){
                        margin-left: 2rem;
                    }
                    &:nth-child(3){
                        margin-left: 0.5rem;
                    }
                    &:nth-child(4){
                        margin-left: 0.5rem;
                    }
                    .react-select__control{
                        background: black;
                        outline: none;
                        border: none;
                        min-height: 1.5rem;
                        box-shadow: none;
                        .react-select__value-container{
                            border: none;
                            .react-select__single-value{
                                font-size: 1rem;
                                color: white;
                            }
                        }
                        .react-select__indicators{
                            border: none;
                            padding: 0;
                            .react-select__indicator-separator{
                                display: none;
                            }
                            .react-select__indicator{
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
                .file-header-search-btn{
                    background: #404040;
                    color: #a8a8a8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: calc(2rem - 1rem);
                    padding: 0.5rem 1.5rem;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    min-width: 40px;
                    text-decoration: none;   
                    &:hover{
                        color: #777676;
                    }
                }
            }
            .file-header-right{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                .file-register{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                    &:hover{
                        color: #777676;
                    }
                }
            }
        }
        .file-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .file-footer{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                margin-top: 4rem;
                .file-footer-content{
                    text-decoration: none;
                    font-size: 1.2rem;
                    padding: .7rem 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    flex-direction: row;
                    border: 0.5px solid #a6a6a6;
                    margin-right: 1rem;
                    &:nth-child(1){
                        margin-left: 10vw;
                    }
                    &:hover{
                        color: #777676;
                    }
                }
            }
            .file-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .file-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 10vw;
                        width: 20vw;
                    }
                    &:nth-child(2){
                        width: 15vw;
                    }
                    &:nth-child(3){
                        width: 10vw;
                    }
                    &:nth-child(4){
                        width: 20vw;
                    }
                }
            }
            .file-body-content{
                width: 100%;
            }
        }
    }
}