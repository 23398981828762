.header-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 100vw;
    background: rgba(29, 29, 29, 0.8);
    margin-bottom: 2rem;
    overflow: hidden;
    .header-data{
        display: flex;
        flex-direction: row;
        
        .header-data-content{
            color: #a6a6a6;
            font-weight: 500;
            font-size: 1.4rem;
            text-decoration: none;
            min-width: 50px;
            &:nth-child(1){
                margin: 0 3vw;
            }
            &:nth-child(2){
                margin: 0 3vw;
            }
            &:nth-child(3){
                margin: 0 3vw;
            }
            &:hover{
                color: #777676;
            }
        }
    }
    .header-logo{
        padding: 0 4vw;
    }
    .link-dae-home{
        font-size: 1.1rem;
        padding: 0 2vw;
        min-width: 120px;
        display: flex;
        justify-content: center;
        color: #a6a6a6;
        text-decoration: none;
        text-align: center;
        &:hover{
            color: #777676;
        }
    }
    .link-dae-app{
        font-size: 1.1rem;
        padding: 0 2vw;
        min-width: 180px;
        text-decoration: none;
        color: #a6a6a6;
        display: flex;
        justify-content: center;
        text-align: center;
        &:hover{
            color: #777676;
        }
    }
    .link-login{
        color: #a6a6a6;
        text-decoration: none;
        display: flex;
        min-width: 100px;
        padding: 0 2vw;
        font-size: 1.3rem;
        &:hover{
            color: #777676;
        }
    }
    .span{
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        .link-login{
            color: #a6a6a6;
            text-decoration: none;
            display: flex;
            min-width: 100px;
            &:nth-child(1){
                padding-right: 0;
                justify-content: flex-end;
            }
            &:nth-child(2){
                padding-left: 0;
            }
            &:hover{
                color: #777676;
            }
        }
    }
}

.goBack{
    position: fixed;
    bottom: 30px;
    left: 30px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    border: 1px solid #a6a6a6;
    cursor: pointer;
    &:hover{
        color: #777676;
    }
}