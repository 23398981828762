.usercontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(2n){
        background-color: #171717;
    }
    .usercontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        text-decoration: none;
        &:nth-child(1){
            margin-left: 3vw;
            width: 10vw;
        }
        &:nth-child(2){
            width: 10vw;
        }
        &:nth-child(3){
            width: 15vw;
        }
        &:nth-child(4){
            width: 10vw;
        }
        &:nth-child(5){
            width: 10vw;
        }
        &:nth-child(6){
            width: 10vw;
        }
        &:nth-child(7){
            width: 10vw;
        }
        &:nth-child(8){
            width: 10vw;
        }
        .usercontent-checkbox{
            width: 20px;
            height: 20px;
            border: 1px solid #a6a6a6;
            color: white;
            text-align: center;
            &:hover{
                color: #777676;
            }
        }
    }
    .usercontent-btns{
        display: flex;
        justify-content: center;
        width: 10vw;
        color: #a6a6a6;
        .usercontent-btn{
            &:nth-child(1){
                cursor: pointer;
            }
            &:nth-child(2){
                cursor: pointer;
                margin-right: 2vw;
            }
            &:hover{
                color: #777676;
            }
        }
    }
}
.vidcontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2n){
        background-color: #171717;
    }
    .vidcontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        &:nth-child(1){
            margin-left: 5vw;
            width: 14vw;
        }
        &:nth-child(2){
            width: 12vw;
        }
        &:nth-child(3){
            width: 12vw;
        }
        &:nth-child(4){
            width: 12vw;
        }
        &:nth-child(5){
            width: 10vw;
        }
        &:nth-child(6){
            margin-left: 4vw;
            padding: 0.5rem 2vw;
            background: #2e3c20;
            color: white;
            cursor: pointer;
            &:hover{
                color: #777676;
            }
        }
    }
    .vidcontent-btns{
        display: flex;
        justify-content: center;
        width: 17vw;
        color: #a6a6a6;
        .vidcontent-btn{
            &:nth-child(1){
                cursor: pointer;
            }
            &:nth-child(2){
                cursor: pointer;
                margin-right: 2vw;
            }
            &:hover{
                color: #777676;
            }
        }
    }
}

.matcontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2n){
        background-color: #171717;
    }
    .matcontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        &:nth-child(1){
            margin-left: 10vw;
            width: 15vw;
        }
        &:nth-child(2){
            width: 15vw;
        }
        &:nth-child(3){
            width: 15vw;
        }
        &:nth-child(4){
            width: 15vw;
        }
        &:nth-child(5){
            padding: 0.5rem 2vw;
            background: #2e3c20;
            color: white;
            cursor: pointer;
            text-decoration: none;
            &:hover{
                color: #777676;
            }
        }
    }
    .matcontent-btns{
        display: flex;
        justify-content: center;
        width: 15vw;
        color: #a6a6a6;
        .matcontent-btn{
            &:nth-child(1){
                cursor: pointer;
            }
            &:nth-child(2){
                cursor: pointer;
                margin-right: 2vw;
            }
            &:hover{
                color: #777676;
            }
        }
    }
}

.groupcontent-container{
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    padding: 0 10vw 0 15vw;
    width: 25vw;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2){
        padding-top: 1rem;
    }
    &:nth-child(3n){
        background-color: #171717;
    }
    &:nth-child(4n){
        background-color: #171717;
    }
    .groupcontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: flex-start;
        width: 60%;
    }
    .groupcontent-btns{
        display: flex;
        flex-direction: row;
        color: #a6a6a6;
        .groupcontent-btn{
            font-size: 1.2rem;
            cursor: pointer;
            &:hover{
                color: #777676;
            }
        }
    }
}

.filecontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2n){
        background-color: #171717;
    }
    .filecontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        text-decoration: none;
        &:nth-child(1){
            margin-left: 10vw;
            width: 20vw;
        }
        &:nth-child(2){
            width: 15vw;
        }
        &:nth-child(3){
            width: 10vw;
        }
        &:nth-child(4){
            width: 20vw;
        }
        &:nth-child(5){
            padding: 0.5rem 2vw;
            background: #2e3c20;
            color: white;
            cursor: pointer;
            &:hover{
                color: #777676;
            }
        }
    }
    .filecontent-btns{
        display: flex;
        justify-content: center;
        width: 15vw;
        color: #a6a6a6;
        .filecontent-btn{
            &:nth-child(1){
                cursor: pointer;
            }
            &:nth-child(2){
                cursor: pointer;
                margin-right: 2vw;
            }
            &:hover{
                color: #777676;
            }
        }
    }
}