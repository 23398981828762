.background-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    position: fixed;
    top: 0;
    .home-background{
        z-index: -1;
        width: 100vw;
        height: 100vh;
        
        overflow: hidden;
    }
}
.home-content-container{
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .home-content{
        color: white;
        font-size: 2rem;
        margin: 1px 0;
        background: rgb(31, 31, 31);
        opacity: 0.8;
        text-decoration: none;
        width: 330px;
        height: 330px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 5rem;
        &:nth-child(1){
            background: #635322;
            margin-right: 0.5rem;
        }
        &:nth-child(2){
            background: #37482a;
            margin-left: 0.5rem;
        }
        .span-1{
            font-size: 2.3rem;
            font-weight: bold;
        }
        span{
            font-size: 2rem;
            font-weight: normal;
        }
        &:hover{
            color: #777676;
        }
    }
}
.admin-btn{
    color: white;
    border: 2px solid #a6a6a6;
    font-weight: 600;
    font-size: 1.5rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
}