.grade-content-dropdown-first{
    color: #a6a6a6;
    cursor: pointer;
    outline: none;
    font-size: 1rem;
    border: 1px solid #a6a6a6;
    height: 2rem;
    min-width: 5rem;
    margin-left: 3rem!important;
    .react-select__control{
        background: black;
        outline: none;
        border: none;
        min-height: 1.5rem;
        box-shadow: none;
        .react-select__value-container{
            border: none;
            .react-select__single-value{
                font-size: 1rem;
                color: white;
            }
        }
        .react-select__indicators{
            border: none;
            padding: 0;
            .react-select__indicator-separator{
                display: none;
            }
            .react-select__indicator{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}
.newstudent-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .newstudent-content-container{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .newstudent-content-dropdown{
            color: white;
            border: none;
            width: 100%;
            min-width: calc(600px + 2rem);
            margin-bottom: 0.3rem;
            outline: none;
            .react-select__control{
                background: #262626;
                border: none;
                border-radius: 0;
                outline: none;
                box-shadow: none;
                padding: 0.9rem 0;
                .react-select__value-container{
                    border: none;
                    .react-select__placeholder{
                        font-size: 1rem;
                        color: #807f80;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                    .react-select__single-value{
                        font-size: 1rem;
                        color: white;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                }
            }
            .react-select__menu{
                background: #727171;
            }
        }
        .newstudent-content-title{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: white;
            padding: 0 1rem;
            font-size: 1.5rem;
            padding-bottom: 2rem;
        }
        .newstudent-content-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            margin-bottom: 0.3rem;
            &::placeholder{
                font-weight: 400;
            } 
        }
        .newstudent-content-group-add-container{
            width: 100%;
            min-width: 600px;
            display: flex;
            justify-content: flex-end;
            margin-top: 0.5rem;
            .newstudent-content-group-add{
                color: white;
                padding: 0.5rem 1.5rem;
                border: 1px solid #807f80;
                border-radius: 20px;
                margin: 0 1rem;
                text-decoration: none;
                &:hover{
                    color: #777676;
                }
            }
        }
        .newstudent-content-btn-container{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .newstudent-content-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid #807f80;
                cursor: pointer;
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
                &:hover{
                    color: #777676;
                }
            }
            .newvid-content-btn{
                width: 100%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid #807f80;
                cursor: pointer;
                &:hover{
                    color: #777676;
                }
            }
        }
    }
}

.react-select__option--is-focused{
    background: #262626!important;
}

#root > div > div > div.newstudent-content-container > div > div > div.react-select__indicators.css-1hb7zxy-IndicatorsContainer > div > svg{
    display: none;
}

#root > div > div.newstudent-content-container > div > div > div.react-select__indicators.css-1hb7zxy-IndicatorsContainer > div > svg{
    display: none;
}

#root > div > div.newstudent-content-container > div > div > div.react-select__indicators.css-1hb7zxy-IndicatorsContainer > span{
    display: none;
}

.newgroup-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .newgroup-content-container{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .newgroup-content-title{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: white;
            padding: 0 1rem;
            font-size: 1.5rem;
            padding-bottom: 2rem;
        }
        .newgroup-content-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            &::placeholder{
                font-weight: 400;
            } 
        }
        .newgroup-content-btn-container{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .newgroup-content-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid rgb(128, 127, 128);
                cursor: pointer;
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
                &:hover{
                    color: #777676;
                }
            }
        }
    }
}

.newfile-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .newfile-sticky{
        width: 50%;
        height: calc(100% - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .newfile-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            &::placeholder{
                font-weight: 400;
            } 
            margin-bottom: 0.5rem;
        }
        .newfile-dropdown{
            color: white;
            border: none;
            width: 100%;
            min-width: calc(600px + 2rem);
            margin-bottom: 0.3rem;
            outline: none;
            .react-select__control{
                background: #262626;
                border: none;
                border-radius: 0;
                outline: none;
                box-shadow: none;
                padding: 0.7rem 0;
                .react-select__value-container{
                    border: none;
                    .react-select__placeholder{
                        font-size: 1rem;
                        color: #807f80;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                    .react-select__single-value{
                        font-size: 1rem;
                        color: white;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                }
            }
            .react-select__menu{
                background: #727171;
            }
        }
        .newfile-addgroup-btn{
            width: 100%;
            min-width: 600px;
            display: flex;
            justify-content: flex-end;
            margin-top: 0.5rem;
            .newfile-addgroup{
                color: white;
                padding: 0.5rem 1.5rem;
                border: 1px solid #807f80;
                border-radius: 20px;
                margin: 0 1rem;
                text-decoration: none;
                &:hover{
                    color: #777676;
                }
            }
        }
        .newfile-selectfile{
            background: #28371b;
            &:hover{
                background: #151d0e;
            }
            color: white;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(90% + 1rem);
            font-size: 1.5rem;
            padding: 1rem;
            min-width: calc(600px - 2rem);
            margin-top: 3rem;
        }
        .newfile-btn{
            width: calc(100% - 2rem);
            padding: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .updatefile-btns{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid rgb(128, 127, 128);
                cursor: pointer;
            }
            .newfile-btns{
                width: 100%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid rgb(128, 127, 128);
                cursor: pointer;
                &:hover{
                    color: #777676;
                }
            }
        }
    }
}