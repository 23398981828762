@font-face { 
  font-family: 'yun'; 
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/윤고딕320.woff) format('woff'); 
}
@font-face { font-family: 'yun'; 
  font-style: bold;
  src: url(./fonts/윤고딕350.woff) format('woff'); 
}
@font-face { font-family: 'yun'; 
  font-style: lighter;
  src: url(./fonts/윤고딕310.woff) 
  format('woff'); 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  font-family: "yun";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input{
  font-family: "yun";
}